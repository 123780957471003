import { useCallback, useMemo } from "react";
import app, { db } from "config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory, useParams } from "react-router-dom";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
const useConnect = () => {
  const [currentUser] = useAuthState(app.auth());
  const history = useHistory();
  const { storeId } = useParams();

  const [value, loading, error] = useDocumentDataOnce(
    db.collection("stores").doc(storeId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const initialValues = useMemo(
    () => ({
      name: value?.name || "",
      email: value?.email || "",
      phone: value?.phone || "",
      address: value?.address || "",
      web: value?.web || "",
      wifiPassword: value?.wifiPassword || "",
      logo: value?.logo || "",
      imgUrl: value?.imgUrl || "",
    }),
    [value]
  );

  const handleClose = useCallback(() => {
    history.push(`/store/${storeId}/view`);
  }, [history, storeId]);

  const updateStore = useCallback(
    async (values) => {
      try {
        if (currentUser) {
          await db
            .collection("stores")
            .doc(storeId)
            .set(
              {
                ...values,
                uid: currentUser.uid,
                editedAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
              },
              { merge: true }
            );
        }

        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [currentUser, history, storeId]
  );

  const handleSubmit = useCallback(
    async (values) => {
      await updateStore(values);
      handleClose();
    },
    [handleClose, updateStore]
  );

  return {
    handleSubmit,
    initialValues,
    handleClose,
    value,
    loading,
    error,
  };
};

export default useConnect;
