import { useCallback, useState } from "react";
import app from "config/firebase";

const useConnect = () => {
  const [isSuccessful, setIsSuccessful] = useState(false);

  const requestResetPassword = useCallback(async (values) => {
    try {
      await app.auth().sendPasswordResetEmail(values.email);
      // history.push("/");
    } catch (error) {
      alert(error);
    }
  }, []);
  const handleSubmit = useCallback(
    async (values, { setErrors, setFieldError }) => {
      try {
        await requestResetPassword(values);
        setIsSuccessful(true);
      } catch (error) {
        if (error.fields) {
          setErrors(error.fields);
        } else {
          setFieldError("email", error.message);
        }
      }
    },
    [requestResetPassword, setIsSuccessful]
  );

  return {
    handleSubmit,
    isSuccessful,
  };
};

export default useConnect;
