import styled from "styled-components";
import DefaultButton from "components/Button";
import { P } from "components/Typography";
import DefaultInput from "components/Input";
import { Form as FormikForm } from "formik";
import { from } from "styles/media";

export const Body = styled.div`
  padding: 2rem 1rem 7.6875rem 1rem;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(DefaultButton)`
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const Input = styled(DefaultInput)`
  margin-top: 1rem;
`;

export const Text = styled(P)`
  max-width: 25.625rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  ${from.laptop} {
    grid-template-columns: 1fr 1fr;
  }
`;
