import styled, { css } from "styled-components";
import { FiCamera } from "react-icons/fi";
import { Body, Caption } from "components/Typography";
import DefaultActionButton from "components/ActionButton";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.125rem dashed ${({ theme }) => theme.colors.gray7};
  cursor: pointer;
  border-radius: 0.3125rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.875rem 0.75rem;
  transition: 0.3s;
  margin-top: 1rem;
  ${({ $error }) =>
    $error &&
    css`
      border-color: ${({ theme }) => theme.colors.redMedium};
    `}
`;

export const IconCamera = styled(FiCamera)`
  color: ${({ theme }) => theme.colors.black};
  height: 3rem;
  width: 3rem;
  margin-right: 0.75rem;
`;
export const ProgressBar = styled.div`
  width: 100%;
`;
export const FillBar = styled.div`
  background-color: ${({ theme }) => theme.colors.corporate};
  height: 5px;
  width: ${(props) => (props.progress ? `${props.progress}%` : "0")};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled(Body)``;

export const Error = styled(Caption)`
  color: ${({ theme }) => theme.colors.outrageousOrange};
  margin-top: 0.25rem;
`;

export const Image = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 0.3125rem;
  object-fit: cover;
`;

export const ActionButton = styled(DefaultActionButton)``;

export const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HelpText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: 0.625rem 0.9375rem;
  border-radius: 0.75rem;
`;
