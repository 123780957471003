import { useCallback, useEffect, useMemo } from "react";
import app, { db } from "config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

const useConnect = ({ onRequestClose, categoryId, category }) => {
  const [currentUser] = useAuthState(app.auth());

  const { storeId } = useParams();

  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  const showCloseButtonHeader = true;

  const handleEditCategory = useCallback(
    async (values) => {
      try {
        if (currentUser) {
          await db
            .collection("stores")
            .doc(storeId)
            .collection("categories")
            .doc(categoryId)
            .update({
              title: { ...values },
              modifiedAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
            });
        }
        onRequestClose();
      } catch (error) {
        alert(error);
      }
    },
    [categoryId, currentUser, onRequestClose, storeId]
  );

  const [value, loading, error] = useDocumentDataOnce(
    db.collection("stores").doc(storeId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const initialValues = useMemo(
    () => ({
      es: category?.title?.es || "",
      en: category?.title?.en || "",
    }),
    [category]
  );

  const handleSubmit = useCallback(
    async (values) => {
      await handleEditCategory(values);
      onRequestClose();
    },
    [onRequestClose, handleEditCategory]
  );

  return {
    value,
    loading,
    error,
    handleSubmit,
    initialValues,
    showCloseButtonHeader,
  };
};

export default useConnect;
