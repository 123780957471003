import React, { memo } from "react";
import useConnect from "./connect";
import { Container, Clickable, Box, Icon, Dropdown } from "./styles";

const DropMenu = ({ ...props }) => {
  const {
    handleClick,
    handleClose,
    actions,
    id,
    anchorEl,
    open,
  } = useConnect();
  return (
    <Container {...props}>
      <Clickable onClick={handleClick}>
        <Box>
          <Icon />
        </Box>
      </Clickable>
      <Dropdown
        actions={actions}
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default memo(DropMenu);
