import React from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { H3 } from "components/Typography";
import { Formik, Field } from "formik";
import { validationSchema } from "./constants";
import DefaultButton from "components/Button";
import DefaultInput from "components/Input";
import { Form as FormikForm } from "formik";
import useConnect from "./connect";

const CreateCategoryModal = ({ onRequestClose, category, categoryId }) => {
  const { handleSubmit, initialValues, showCloseButtonHeader } = useConnect({
    onRequestClose,
    categoryId,
    category,
  });

  return (
    <Backdrop>
      <ModalContainer>
        <Header>
          <HeaderContent>
            {showCloseButtonHeader ? (
              <CloseButton onClick={onRequestClose}>
                <CloseIcon />
              </CloseButton>
            ) : (
              <div />
            )}

            <Title>Editar Categoría</Title>
            <EmptyBox />
          </HeaderContent>
        </Header>
        <ModalWrapper>
          <Formik
            enableReinitialize={true}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <Field type="text" name="es" as={Input} label="Título" />

                <Field type="text" name="en" as={Input} label="Title" />

                <Button
                  variant="primary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Form>
            )}
          </Formik>
        </ModalWrapper>

        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder medium" />
        <div className="placeholder" />
      </ModalContainer>
    </Backdrop>
  );
};

export default CreateCategoryModal;

export const ModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 5px;
  max-width: 100%;
  margin: 50px auto;
  width: 560px;
`;

export const ModalWrapper = styled.div`
  padding: 30px;
`;

export const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;
export const MaxWidth = styled.div`
  margin: 0 auto;
  /* max-width: 74rem; */
`;

export const Header = styled.header`
  background: ${({ theme }) => theme.colors.gray1};
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
  /* position: fixed; */
  width: 100%;
  /* z-index: 2; */
`;

export const HeaderContent = styled(MaxWidth)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.5625rem 0;
`;

export const Title = styled(H3)``;

export const CloseButton = styled.button`
  color: ${({ theme }) => theme.colors.gray6};
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 30px;
`;

export const CloseIcon = styled(FiX)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const EmptyBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(DefaultButton)`
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const Input = styled(DefaultInput)`
  margin-top: 1rem;
`;
