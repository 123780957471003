import * as React from "react";

function LogoSimbolo(props) {
  return (
    <svg viewBox="0 0 227.2 227.1" {...props}>
      <path
        d="M113.6 0c-5.3 0-10.5.4-15.7 1.1.2.9.3 1.8.2 2.7-.6 11-8.9 12.9-16.5 12.9 0 0 8.9 10.3 1.8 20.6s-21.9.1-21.9.1 5.4 11.5-4.5 16.2c-13.5 6.5-18-6.3-18-6.3s-2.1 10.5-15.1 7.2c-2.4-.6-4.2-1.7-5.5-3C6.8 69.3 0 90.6 0 113.5c0 62.7 50.9 113.6 113.6 113.6s113.6-50.9 113.6-113.6C227.3 50.9 176.4 0 113.6 0z"
        fill="#764abc"
      />
      <path
        d="M141.5 83.3c5.8 5.7 8.6 14 8.6 24.9v44.3h-13.9v-42.7c0-7.5-1.8-13.1-5.4-16.8-3.6-3.8-8.7-5.7-15.4-5.7-7.5 0-13.5 2.2-17.9 6.6S91 104.6 91 112.8v39.6H77.1V75.5h13.4v11.6c2.8-4 6.6-7 11.4-9.1s10.2-3.2 16.3-3.2c9.8 0 17.6 2.8 23.3 8.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default LogoSimbolo;
