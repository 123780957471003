import { useCallback } from "react";
import app, { db } from "config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";

const useConnect = ({ handleClose }) => {
  const [currentUser] = useAuthState(app.auth());
  const history = useHistory();
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    web: "",
    wifiPassword: "",
    logo: "",
    imgUrl: "",
  };

  const handleCreateStore = useCallback(
    async (values) => {
      console.log(values);
      try {
        if (currentUser) {
          await db
            .collection("stores")
            .doc()
            .set(
              {
                ...values,
                uid: currentUser.uid,
                createdAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
              },
              { merge: true }
            );
        }

        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [currentUser, history]
  );

  const handleSubmit = useCallback(
    async (values) => {
      await handleCreateStore(values);
      handleClose();
    },
    [handleClose, handleCreateStore]
  );

  return {
    // isLoading,
    handleSubmit,
    initialValues,
  };
};

export default useConnect;
