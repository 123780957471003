import { useCallback, useMemo } from "react";
import app, { db } from "config/firebase";
import { useHistory, useParams } from "react-router-dom";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
const useConnect = () => {
  const history = useHistory();
  const { storeId, categoryId } = useParams();

  const [value, loading, error] = useDocumentDataOnce(
    db
      .collection("stores")
      .doc(storeId)
      .collection("categories")
      .doc(categoryId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const initialValues = useMemo(
    () => ({
      title: {
        es: value?.title.es || "",
        en: value?.title.en || "",
      },
    }),
    [value]
  );

  const handleClose = useCallback(() => {
    history.push(`/store/${storeId}/categories`);
  }, [history, storeId]);

  const updateCategory = useCallback(
    async (values) => {
      try {
        await db
          .collection("stores")
          .doc(storeId)
          .collection("categories")
          .doc(categoryId)
          .set(
            {
              ...values,
              editedAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          );

        setTimeout(() => {
          history.push(`/store/${storeId}/categories`);
        }, 3000);
      } catch (error) {
        alert(error);
      }
    },
    [categoryId, history, storeId]
  );

  const handleSubmit = useCallback(
    async (values) => {
      await updateCategory(values);
      handleClose();
    },
    [handleClose, updateCategory]
  );

  return {
    handleSubmit,
    initialValues,
    handleClose,
    loading,
    error,
    value,
  };
};

export default useConnect;
