import React, { useCallback, memo } from "react";
import { useField, useFormikContext, ErrorMessage } from "formik";
import {
  Container,
  Component,
  Label,
  IconError,
  IconClean,
  InputContainer,
  Button,
  Error,
  MaskedInput,
} from "./styles";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: "€",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

const Input = ({ className, label, value, disabled, isMoney, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  const { setFieldValue } = useFormikContext();

  const handleClean = useCallback(() => {
    setFieldValue(field.name, "");
  }, [setFieldValue, field.name]);

  return (
    <Container className={className}>
      {label && (
        <Label as="label" $disabled={disabled} htmlFor={field.name}>
          {label}
        </Label>
      )}

      <InputContainer $error={hasError}>
        {isMoney ? (
          <CurrencyInput
            {...field}
            {...props}
            id={field.name}
            disabled={disabled}
            placeholder="0,00€"
          />
        ) : (
          <Component
            {...field}
            {...props}
            id={field.name}
            disabled={disabled}
          />
        )}

        {hasError && <IconError />}
        {!hasError && field.value !== "" && (
          <Button onClick={handleClean} type="button" $disabled={disabled}>
            <IconClean />
          </Button>
        )}
      </InputContainer>
      <ErrorMessage component={Error} name={field.name} />
    </Container>
  );
};

export default memo(Input);
