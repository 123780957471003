import React, { createContext, useState, useContext } from "react";

const defaultValue = {
  open: { status: false, modal: "" },
  handleCloseModal: () => {},
  handleOpenModalLogin: () => {},
  handleOpenModalRegister: () => {},
  handleOpenModalForgot: () => {},
};

const ModalContext = createContext(defaultValue);

export const useModalContext = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState({ status: false, modal: "" });

  const handleOpenModalLogin = () => setOpen({ status: true, modal: "login" });

  const handleOpenModalRegister = () =>
    setOpen({ status: true, modal: "register" });

  const handleOpenModalForgot = () =>
    setOpen({ status: true, modal: "forgot" });

  const handleCloseModal = () => setOpen({ status: false, modal: "" });

  return (
    <ModalContext.Provider
      value={{
        open,
        handleOpenModalLogin,
        handleOpenModalRegister,
        handleOpenModalForgot,
        handleCloseModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
