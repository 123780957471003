import styled, { css } from "styled-components";
import { FiChevronDown } from "react-icons/fi";
import { Caption, SmallSemi } from "components/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1.25rem;
`;
export const SelectContainer = styled.div`
  display: inline-flex;
  position: relative;
`;

export const Component = styled.select`
  color: ${({ theme }) => theme.colors.gray7};
  outline: none;
  font-size: 0.875rem;
  line-height: 1.225rem;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.gray2};
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding: 0.875rem 0.75rem;
  transition: 0.3s;
  &:focus-within {
    border: 0.125rem solid ${({ theme }) => theme.colors.corporate};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray3};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.gray4};
  }
  ${({ $error }) =>
    $error &&
    css`
      border-color: ${({ theme }) => theme.colors.redMedium};
    `}
  ${({ $filled }) =>
    $filled &&
    css`
      color: ${({ theme }) => theme.colors.ebonyClay};
    `}
`;
export const Icon = styled(FiChevronDown)`
  position: absolute;
  pointer-events: none;
  color: ${({ theme }) => theme.colors.gray7};
  top: 2.0625rem;
  right: 0.75rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray4};
    `}
`;

export const Label = styled(SmallSemi)`
  color: ${({ theme }) => theme.colors.gray7};
  margin-bottom: 0.25rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray4};
    `}
`;
export const Error = styled(Caption)`
  color: ${({ theme }) => theme.colors.outrageousOrange};
  margin-top: 0.25rem;
`;

export const HelperText = styled(Caption)`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.gray7};
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray4};
    `}
`;
