import React from "react";
import { Route, Switch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container, Tabs, Tab } from "./styles";
import ViewStore from "containers/Main/Store/View";
import OrderCategoriesList from "containers/Main/Categories/OrderCategoriesList";
import QrStore from "containers/Main/Store/Qr";
import * as ROUTES from "constants/routes";
import Layout from "components/Layout";
import Header from "components/store/Header";
import useConnect from "./connect";

const Store = () => {
  const { storeId } = useParams();
  const { valueStore, loadingStore, errorStore } = useConnect();
  return (
    <Layout>
      {errorStore && <strong>Error: {JSON.stringify(errorStore)}</strong>}
      {loadingStore && <span>Document: Loading...</span>}
      {valueStore && <Header data={valueStore} />}
      <Container>
        <Tabs>
          <Tab label="Carta" exact to={`/store/${storeId}/view`} />
          <Tab label="Categorias" to={`/store/${storeId}/categories`} />
          <Tab label="QR" to={`/store/${storeId}/qr`} />
        </Tabs>
        <Switch>
          <Route path={ROUTES.VIEW_STORE} component={ViewStore} />
          <Route
            path={ROUTES.ORDER_CATEGORIES}
            component={OrderCategoriesList}
          />
          <Route exact path={ROUTES.QR_STORE} component={QrStore} />
        </Switch>
      </Container>
    </Layout>
  );
};

export default Store;
