import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import firebase from "firebase/app";
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_NEASUREMENT_ID,
});
firebase.analytics();

export default app;
export const auth = firebase.auth();
export const auth2 = firebase.auth;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const fieldValue = firebase.fieldValue;

export const getProductsCategoryList = (storeId) => {
  return db.collection("stores").doc(storeId).collection("products").get();
};
