import React from "react";
import ProductList from "containers/Main/Products/ProductList";
import useConnect from "./connect";
import { Title, AddButton, Container, Row } from "./styles";

export default function ViewStore() {
  const { goToAddProduct, valueCategories, errorCategories, id } = useConnect();

  return (
    <Container>
      {errorCategories && (
        <strong>Error: {JSON.stringify(errorCategories)}</strong>
      )}
      <Row>
        <Title>Carta</Title>
      </Row>

      <AddButton onClick={goToAddProduct}>Añadir Producto</AddButton>

      {valueCategories &&
        valueCategories.docs.map((doc) => (
          <ProductList
            storeId={id}
            categoryId={doc.id}
            key={doc.id}
            categoryName={doc.data().title.es}
            category={doc.data()}
          />
        ))}
    </Container>
  );
}
