import React, { memo } from "react";
import { useField, ErrorMessage } from "formik";
import {
  Container,
  Component,
  Label,
  IconError,
  InputContainer,
  Error,
} from "./styles";

const TextArea = ({ className, label, value, disabled, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  return (
    <Container className={className}>
      {label && (
        <Label as="label" $disabled={disabled} htmlFor={field.name}>
          {label}
        </Label>
      )}

      <InputContainer $error={hasError}>
        <Component {...field} {...props} id={field.name} disabled={disabled}>
          {value}
        </Component>
        {hasError && <IconError />}
      </InputContainer>
      <ErrorMessage component={Error} name={field.name} />
    </Container>
  );
};

export default memo(TextArea);
