import React, { useContext } from "react";
import Guest from "containers/Guest";
import Main from "containers/Main";
import { AuthContext } from "providers/AuthProvider";

const Root = () => {
  const { authenticated, loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) return <p>loading...</p>;

  if (authenticated) return <Main />;

  return <Guest />;
};

export default Root;
