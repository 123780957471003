import React, { useEffect, useState } from "react";
import app from "config/firebase";
export const AuthContext = React.createContext({});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoadingAuthState(false);
    });
  }, []);
  return React.createElement(
    AuthContext.Provider,
    {
      value: {
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
      },
    },
    children
  );
};
