import { useCallback, useMemo } from "react";
import app, { db } from "config/firebase";
import { useHistory, useParams } from "react-router-dom";
import { useCollectionOnce } from "react-firebase-hooks/firestore";

const useConnect = () => {
  const history = useHistory();
  const { storeId } = useParams();
  const initialValues = {
    name: {
      es: "",
      en: "",
    },
    description: {
      es: "",
      en: "",
    },
    category: "",
    price: "",
    prices: [],
    allergens: [],
    featured: false,
    hidden: false,
    takeaway: false,
    imgUrl: "",
  };

  const [
    valueCategories,
    loadingCategories,
    errorCategories,
  ] = useCollectionOnce(
    db.collection("stores").doc(storeId).collection("categories"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const options = useMemo(() => {
    const newOptions = [];
    newOptions.push({ label: "Selecciona una categoría", value: "" });
    valueCategories &&
      valueCategories.docs.map((category) => {
        newOptions.push({
          label: category.data().title.es,
          value: category.id,
        });
        return null;
      });

    return newOptions;
  }, [valueCategories]);

  const handleCreateProduct = useCallback(
    async (values) => {
      try {
        // async magic goes here...
        db.collection("stores")
          .doc(storeId)
          .collection("products")
          .doc()
          .set({
            ...values,
            createdAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
          });
      } catch (error) {
        alert(error);
      }
    },
    [storeId]
  );

  const handleClose = useCallback(() => {
    history.push(`/store/${storeId}/view`);
  }, [history, storeId]);

  const handleSubmit = useCallback(
    async (values) => {
      await handleCreateProduct(values);

      history.push(`/store/${storeId}/view`);
    },
    [handleCreateProduct, history, storeId]
  );

  return {
    // loadOptions,
    handleSubmit,
    initialValues,
    valueCategories,
    loadingCategories,
    errorCategories,
    handleClose,
    options,
  };
};

export default useConnect;
