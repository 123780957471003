import styled from "styled-components";
import DefaultButton from "components/Button";
import { Subtitle as DefaultSubtitle, H3 } from "components/Typography";

export const Subtitle = styled(DefaultSubtitle)``;
export const Button = styled(DefaultButton)`
  margin-top: 1.875rem;
`;

export const Response = styled.pre`
  font-size: 0.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
  border-radius: 0.75rem;
  margin-top: 1.875rem;
`;

export const SuccessTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.greenMedium};
`;
