import React from "react";
import { FiPrinter, FiTrash2, FiEdit, FiSave } from "react-icons/fi";
import Component, { IconContainer } from "./styles";

const ActionButton = ({ onClick, variant, ...props }) => {
  return (
    <Component onClick={onClick} $variant={variant} {...props}>
      <IconContainer>
        {variant === "save" && <FiSave />}
        {variant === "print" && <FiPrinter />}
        {variant === "trash" && <FiTrash2 />}
        {variant === "edit" && <FiEdit />}
      </IconContainer>
    </Component>
  );
};

export default ActionButton;
