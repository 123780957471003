import * as yup from "yup";

export const initialValues = {
  email: "",
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email no es válido!")
    .required("Email es requerido!"),
});
