import styled, { css } from "styled-components";
import { FiPlus } from "react-icons/fi";
import addAlpha from "utils/addAlpha";

export const Icon = styled(FiPlus)`
  font-size: 1.5rem;
  margin-right: 0.75rem;
  color: ${({ theme }) => theme.colors.greenDark};
`;

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => addAlpha(theme.colors.navyGreen, 0.8)};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  :hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.greenMedium};
    ${Icon} {
      color: ${({ theme }) => theme.colors.greenMedium};
    }
  }

  :active:not(:disabled) {
    color: ${({ theme }) => theme.colors.greenExtraDark};
    ${Icon} {
      color: ${({ theme }) => theme.colors.greenExtraDark};
    }
  }

  :disabled {
    color: ${({ theme }) => theme.colors.gray4};
    ${Icon} {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  ${({ $size }) => {
    switch ($size) {
      case "big":
        return css`
          font-size: 1.3125rem;
          line-height: 1.8375rem;
          font-weight: 700;
        `;
      case "small":
      default:
        return css`
          font-size: 1.1875rem;
          line-height: 1.6625rem;
          font-weight: 600;
        `;
    }
  }}
`;

export default Button;
