import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import app, { db } from "config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const useConnect = () => {
  const history = useHistory();

  const login = useCallback(
    async (values) => {
      try {
        const authResponse = await app
          .auth()
          .createUserWithEmailAndPassword(values.email, values.password);
        await db.collection("users").doc(authResponse.user.uid).set(
          {
            email: values.email,
          },
          { merge: true }
        );
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const [currentUser] = useAuthState(app.auth());
  localStorage.setItem("authUser", JSON.stringify(currentUser));

  const { push } = useHistory();

  const handleRegisterRedirect = useCallback(() => push("/"), [push]);

  const handleSubmit = useCallback(
    async (values, { setErrors, setFieldError }) => {
      try {
        await login(values);
      } catch (error) {
        if (error.fields) {
          setErrors(error.fields);
        } else {
          setFieldError("password", error.message);
        }
      }
    },
    [login]
  );

  return {
    handleRegisterRedirect,
    handleSubmit,
  };
};

export default useConnect;
