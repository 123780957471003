import React from "react";
import { Formik, Field } from "formik";
import { Row, Form, Input, Button } from "./styles";
import { validationSchema } from "./constants";
import useConnect from "./connect";
import ModalScreen from "components/ModalScreen";

export default function CreateStore() {
  const { handleSubmit, initialValues, handleClose } = useConnect();

  return (
    <>
      <ModalScreen title="Crear categoría" open handleClose={handleClose}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Row>
                <Field
                  type="text"
                  name="title.es"
                  as={Input}
                  label="Nombre 🇪🇸"
                />
                <Field
                  type="text"
                  name="title.en"
                  as={Input}
                  label="Nombre 🇺🇸 "
                />
              </Row>

              <Button
                variant="primary"
                fullWidth
                type="submit"
                disabled={isSubmitting}
              >
                Guardar
              </Button>
            </Form>
          )}
        </Formik>
      </ModalScreen>
    </>
  );
}
