import React from "react";
import useLogic from "./logic";
import { Component } from "./styles";

const Button = ({
  children,
  fullWidth,
  icon,
  iconPosition,
  onClick: defaultOnClick,
  variant,
  ...rest
}) => {
  const { onClick } = useLogic({ defaultOnClick });

  return (
    <Component
      fullWidth={fullWidth}
      iconPosition={iconPosition}
      onClick={onClick}
      variant={variant}
      {...rest}
    >
      {icon}
      <span>{children}</span>
    </Component>
  );
};

export default Button;
