import { db } from "config/firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useParams, useHistory } from "react-router-dom";

const useConnect = () => {
  const { storeId } = useParams();

  const [valueStore, loadingStore, errorStore] = useDocument(
    db.doc(`stores/${storeId}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [valueCategories, loadingCategories, errorCategories] = useCollection(
    db
      .collection("stores")
      .doc(storeId)
      .collection("categories")
      .orderBy("order"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const history = useHistory();
  const goToAddProduct = () => {
    history.push(`/store/${storeId}/product/create`);
  };

  return {
    goToAddProduct,
    valueStore,
    loadingStore,
    errorStore,
    valueCategories,
    loadingCategories,
    errorCategories,
    storeId,
  };
};

export default useConnect;
