import { useState, useEffect, useMemo } from "react";
import { db } from "config/firebase";
import { useParams, useHistory } from "react-router-dom";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import arrayMove from "array-move";
import { confirmAlert } from "react-confirm-alert";

const useConnect = () => {
  const { storeId } = useParams();
  const history = useHistory();
  const [itemList, setItemList] = useState([]);

  const query = useMemo(() => {
    const messagesRef = db
      .collection("stores")
      .doc(storeId)
      .collection("categories")
      .orderBy("order");
    const query = messagesRef;
    return query;
  }, [storeId]);

  const [data, status, error] = useCollectionOnce(query);

  useEffect(() => {
    console.log(data);
    if (data) {
      setItemList(data.docs);
    }
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line
    itemList.map((doc, i) => {
      db.collection("stores")
        .doc(storeId)
        .collection("categories")
        .doc(doc.id)
        .update({
          order: parseInt(i),
        })
        .then(function () {
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    });
  }, [itemList, storeId]);

  const deleteCategoty = async (categoryId) => {
    confirmAlert({
      title: "Confirmar eliminar",
      message: "¿Estás seguro que quieres eliminar esta categoría?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            db
              .collection("stores")
              .doc(storeId)
              .collection("categories")
              .doc(categoryId)
              .delete()
              .then(function () {
                history.push(`/store/${storeId}/categories`);
              })
              .catch(function (error) {
                console.error("Error removing document: ", error);
              }),
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    setItemList(arrayMove(itemList, oldIndex, newIndex));
  };

  const goToAddCategory = () => {
    history.push(`/store/${storeId}/category/create`);
  };
  const goToEditCategory = (categoryId) => {
    history.push(`/store/${storeId}/categories/${categoryId}/edit`);
  };
  return {
    storeId,
    data,
    status,
    error,
    itemList,
    onSortEnd,
    goToAddCategory,
    goToEditCategory,
    deleteCategoty,
  };
};

export default useConnect;
