import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import app, { auth, auth2, db } from "config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const useConnect = () => {
  const history = useHistory();

  const login = useCallback(
    async (values) => {
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(values.email, values.password);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );
  var provider = new auth2.GoogleAuthProvider();
  const loginWithGoogle = async () => {
    try {
      auth
        .signInWithPopup(provider)
        .then(async (res) => {
          await db.collection("users").doc(res.user.uid).set(
            {
              email: res.user.email,
            },
            { merge: true }
          );
        })
        .catch((error) => {
          console.log(error.message);
        });
      // history.push("/");
    } catch (error) {
      alert(error);
    }
  };

  const [currentUser] = useAuthState(app.auth());
  localStorage.setItem("authUser", JSON.stringify(currentUser));

  // if (currentUser) {
  //   return <Redirect to="/" />;
  // }

  const { push } = useHistory();

  const handleRegisterRedirect = useCallback(() => push("/register"), [push]);

  const handleSubmit = useCallback(
    async (values, { setErrors, setFieldError }) => {
      try {
        await login(values);
      } catch (error) {
        if (error.fields) {
          setErrors(error.fields);
        } else {
          setFieldError("password", error.message);
        }
      }
    },
    [login]
  );

  return {
    handleRegisterRedirect,
    handleSubmit,
    loginWithGoogle,
  };
};

export default useConnect;
