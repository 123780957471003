import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { db } from "config/firebase";
import useAuth from "hooks/useAuth";
// import { loadStripe } from "@stripe/stripe-js";
// import { STRIPE_PUBLISHABLE_KEY } from "constants/strapi";
const axios = require("axios");

// const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const useConnect = () => {
  const urlParams = new URLSearchParams(useLocation().search);
  const sessionId = urlParams.get("session_id");
  const currentUser = useAuth();
  const customerId = null;
  const [sessionStripe, setSessionStripe] = useState("");

  useEffect(() => {
    if (sessionId) {
      fetch("/checkout-session?sessionId=" + sessionId)
        .then(function (result) {
          return result.json();
        })
        .then(async function (session) {
          setSessionStripe(session);
          if (currentUser) {
            await db
              .collection("users")
              .doc(currentUser.currentUser.uid)
              .collection("subscriptions")
              .doc(session.subscription)
              .set({
                ...session,
              });
          }
          // We store the customer ID here so that we can pass to the
          // server and redirect to customer portal. Note that, in practice
          // this ID should be stored in your database when you receive
          // the checkout.session.completed event. This demo does not have
          // a database, so this is the workaround. This is *not* secure.
          // You should use the Stripe Customer ID from the authenticated
          // user on the server.
          // customerId = session.customer;

          // {
          //   "id": "cs_test_a1b35rcMeA5ZzL1nCf7cSI5W0o7pLylEt7e5dYNZrU4ULO64dqAwUEiVxP",
          //   "object": "checkout.session",
          //   "allow_promotion_codes": null,
          //   "amount_subtotal": 2000,
          //   "amount_total": 2000,
          //   "billing_address_collection": null,
          //   "cancel_url": "http://localhost:4242/canceled.html",
          //   "client_reference_id": null,
          //   "currency": "eur",
          //   "customer": "cus_J1skictj0MTMlb",
          //   "customer_details": {
          //     "email": "enmaska@gmail.com",
          //     "tax_exempt": "none",
          //     "tax_ids": []
          //   },
          //   "customer_email": null,
          //   "livemode": false,
          //   "locale": null,
          //   "metadata": {},
          //   "mode": "subscription",
          //   "payment_intent": null,
          //   "payment_method_types": [
          //     "card"
          //   ],
          //   "payment_status": "paid",
          //   "setup_intent": null,
          //   "shipping": null,
          //   "shipping_address_collection": null,
          //   "submit_type": null,
          //   "subscription": "sub_J1skWU30sfA58Y",
          //   "success_url": "http://localhost:3000/payment/success?session_id={CHECKOUT_SESSION_ID}",
          //   "total_details": {
          //     "amount_discount": 0,
          //     "amount_tax": 0
          //   }
          // }
        })
        .catch(function (err) {
          console.log("Error when fetching Checkout session", err);
        });

      // In production, this should check CSRF, and not pass the session ID.
      // The customer ID for the portal should be pulled from the
      // authenticated user on the server.
    }
  }, [currentUser, sessionId]);

  const customerPortal = async (sessionId) => {
    // const stripe = await stripePromise;
    axios
      .post("http://localhost:4242/customer-portal", {
        sessionId,
      })
      .then(function (response) {
        window.location.href = response.data.url;
        return null;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return {
    customerId,
    sessionStripe,
    customerPortal,
  };
};

export default useConnect;
