import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset};
  html {
    font-size: 100%; 
  }
  html, button, input, select, textarea {
    font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.gray1};
    color: ${({ theme }) => theme.colors.navyGreen};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga","kern";
    font-variant-numeric: proportional-nums;
  }
  * {
    box-sizing: border-box;
  }

::selection {
  background: #47d3d9;
  color: #fff;
}
a {
  color: #764abc;
}
a:hover {
  color: #0e7d83;
}
label {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-font-weight: 400;
}
input {
  margin: 0;
  padding: 0;
}
.thumbnail {
  object-fit: contain !important;
  height: 100px !important;
  width: 100px !important;
}

.css-yk16xz-control {
  color: #262626 !important;
  border: 1px solid #e0e0e0 !important;
  font-size: 16px !important;
  height: 48px !important;
  line-height: 24px !important;
  margin-bottom: 20px !important;
  border-radius: 0 !important;
}
.select__control:focus {
  outline: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05),
    inset 0 0 0 100px #fff, 0 0 0 3px rgb(118, 74, 188, 0.3);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05), inset 0 0 0 100px #fff,
    0 0 0 3px rgb(118, 74, 188, 0.3);
  border: 2px solid #764abc !important;
}
.select__control--menu-is-open,
.select__control--is-focused {
  margin-bottom: 20px;
  height: 48px !important;
}
.select__control--is-focused:hover {
  outline: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05),
    inset 0 0 0 100px #fff, 0 0 0 3px rgb(118, 74, 188, 0.3);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05), inset 0 0 0 100px #fff,
    0 0 0 3px rgb(118, 74, 188, 0.3);
  border: 2px solid #764abc !important;
}

.select__placeholder {
  padding-left: 15px;
}

.css-b8ldur-Input {
  padding-left: 15px;
}
.select__menu {
  border-radius: 0;
}

.orderContainer {
  width: 1024px;
  height: 350px;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
}

.orderItem {
  float: left;
  width: 250px;
  height: 250px;
  padding: 8px;
  background: transparent;
  border: 0;
}

.inner-item {
  height: 212px;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  width: 400px;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}
[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #ffffff;
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
  border: 2px solid rgba(118, 74, 188, 0.6);
  border-radius: 5px;
  color: #747474;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: inherit;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  height: 48px !important;
  padding: 10px 20px;
  line-height: 24px !important;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  width: 100%;

  &:hover {
    background-color: darken(#f15d22, 10%);
  }

  &.btn-1 {
    background-color: #f79159;
    box-shadow: 0 6px darken(#f79159, 10%);
    transition: none;

    &:hover {
      box-shadow: 0 4px darken(#f79159, 10%);
      top: 2px;
    }
  }
}



/* CHECKBOX */
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #764abc;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #764abc;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 30px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 30px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 10px;
    height: 12px;
        border: 3px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
/* Modal */

.modal__backdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modal__container {
	background: #fff;
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 560px;
}

.modal__title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

#react-qrcode-logo {
  width: 100%!important;
  height: auto!important;
  
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none;
}
`;
export default GlobalStyle;
