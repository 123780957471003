const keysOf = (o) => Object.keys(o);
export const sizes = {
  mobile: "43.1238rem",
  tablet: "55.7488rem",
  laptop: "74.9988rem",
  desktop: "89.9988rem",
};
const minWidthQuery = (width) => `@media (min-width: ${width})`;
export const from = keysOf(sizes).reduce(
  (acc, key) =>
    Object.assign(Object.assign({}, acc), { [key]: minWidthQuery(sizes[key]) }),
  {}
);
