import { Subtitle, Caption } from "components/Typography";
import styled from "styled-components";
import { from } from "styles/media";

export const Component = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
  :hover {
    box-shadow: 0rem 0.5rem 1rem rgba(35, 35, 35, 0.02),
      0rem 0.5rem 2rem rgba(35, 35, 35, 0.02);
  }
`;

export const Wrapper = styled.div`
  padding: 1.25rem;
`;

export const Title = styled(Subtitle)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;

  ${from.tablet} {
    width: 11.25rem;
    height: 11.25rem;
  }
  border-radius: 0.75rem;
  object-fit: cover;
`;

export const Date = styled(Caption)``;
