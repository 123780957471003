import { useState, useRef, useCallback } from "react";
import { db } from "config/firebase";
import { useParams } from "react-router-dom";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
const useConnect = () => {
  const { storeId } = useParams();

  const [valueStore, loadingStore, errorStore] = useDocumentOnce(
    db.doc(`stores/${storeId}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const downloadQR = () => {
    const canvas = qrRef.current.canvas.current;

    const pngUrl = canvas
      .toDataURL("image/jpg")
      .replace("image/jpg", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${storeId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const [state, setState] = useState(false);
  const [color, setColor] = useState("#000000");
  const [background, setBackground] = useState("#FFFFFF");

  const handleChange = useCallback((event) => {
    setState(event.target.checked);
  }, []);

  const qrRef = useRef();

  return {
    storeId,
    valueStore,
    loadingStore,
    errorStore,
    downloadQR,
    state,
    color,
    setColor,
    background,
    setBackground,
    handleChange,
    qrRef,
  };
};

export default useConnect;
