import React from "react";
import LogoSimbolo from "components/LogoSimbolo";
import DropMenu from "containers/Shared/DropMenu";
import { Container, Link } from "./styles";
function Header() {
  return (
    <Container>
      <Link to="/">
        <LogoSimbolo width="40" />
      </Link>

      <div>
        <DropMenu />
      </div>
    </Container>
  );
}

export default Header;
