import React from "react";
import useLogic from "./logic";
import { getThumbnailURL } from "../../../helpers";

import {
  Button,
  Avatar,
  Col4,
  Title,
  WidgetBar,
  Breadcrumbbar,
  Row,
  Section,
} from "./styles";
function Header(props) {
  const { data } = props;
  const { goToEdit, storeId } = useLogic();
  return (
    <Section>
      <Breadcrumbbar>
        <Row>
          <Col4>
            {data.data().logo && (
              <Avatar src={getThumbnailURL(data.data().logo)} />
            )}

            <Title>{data.data().name}</Title>
          </Col4>

          <WidgetBar>
            {/* <LinkButton to={`/store/${data.id}/product/create`}>
                <Icon name="plus circle" /> Añadir Producto
              </LinkButton>
              <LinkButton to={`/store/${data.id}/category/create`} primary>
                <Icon name="plus circle" /> Añadir Categoría
              </LinkButton>
              <LinkButton to={`/store/${data.id}/categories/order`} primary>
                <IconSort width="10" /> Ordenar Categorías
              </LinkButton>
              <LinkButton to={`/store/qr/${data.id}/`}>
                <IconQr width="11" /> QR
              </LinkButton> */}
            <Button variant="tertiary" onClick={() => goToEdit(storeId)}>
              Editar Carta
            </Button>
          </WidgetBar>
        </Row>
      </Breadcrumbbar>
    </Section>
  );
}

export default Header;
