import styled, { css } from "styled-components";

export const Component = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  height: 2.75rem;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1.1875rem;
  padding: 0 1rem;
  box-shadow: rgb(32 34 37 / 30%) 0rem 0.625rem 1.25rem;
  & svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ iconPosition }) => {
    switch (iconPosition) {
      case "left":
        return css`
          flex-direction: row;

          & span {
            margin-left: 0.75rem;
          }
        `;
      case "right":
        return css`
          flex-direction: row-reverse;

          & span {
            margin-right: 0.75rem;
          }
        `;
      default:
        return "";
    }
  }}

    ${({ theme, variant }) => {
      switch (variant) {
        case "secondary":
          return css`
            background-color: ${theme.colors.white};
            border: 0.0625rem solid ${theme.colors.black};
            color: ${theme.colors.black};

            &:hover {
              background-color: ${theme.colors.gray1};
              color: ${theme.colors.black};
            }
          `;
        case "tertiary":
          return css`
            background-color: ${theme.colors.corporate};

            &:hover {
              background-color: ${theme.colors.corporateMedium};
            }
          `;
        case "primary":
        default:
          return css`
            &:hover {
              background-color: ${theme.colors.black};
            }
          `;
      }
    }}
`;
