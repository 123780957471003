import React, { useMemo } from "react";
import { Formik, Field } from "formik";
import { Row, Form, Input, Button } from "./styles";
import { validationSchema } from "./constants";
import useConnect from "./connect";
import ModalScreen from "components/ModalScreen";
import ImageUploaded from "components/ImageUpload";
export default function CreateStore({
  title,
  open,
  handleClose,
  previousData,
}) {
  const { handleSubmit } = useConnect({
    handleClose,
  });
  const initialValues = useMemo(
    () => ({
      name: previousData?.name || "",
      email: previousData?.email || "",
    }),
    [previousData]
  );
  return (
    <>
      <ModalScreen title={title} open={open} handleClose={handleClose}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form noValidate>
              <Field type="text" name="name" as={Input} label="Nombre" />

              <Field type="text" name="address" as={Input} label="Dirección" />

              <Row>
                <Field type="email" name="email" as={Input} label="Email*" />
                <Field type="text" name="phone" as={Input} label="Teléfono" />
              </Row>
              <Row>
                <Field type="text" name="web" as={Input} label="Web" />
                <Field
                  type="text"
                  name="wifiPassword"
                  as={Input}
                  label="Password WIFI"
                />
              </Row>
              <Row>
                <ImageUploaded
                  setFieldValue={setFieldValue}
                  values={values}
                  name="logo"
                  label="Logo"
                  folder="logos"
                />
                <ImageUploaded
                  setFieldValue={setFieldValue}
                  values={values}
                  name="imgUrl"
                  label="Imagen de fondo"
                  folder="bg"
                />
              </Row>

              <Button variant="primary" fullWidth type="submit">
                Guardar
              </Button>
            </Form>
          )}
        </Formik>
      </ModalScreen>
    </>
  );
}
