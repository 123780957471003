import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";
const Checkbox = ({
  type = "checkbox",
  name,
  value,
  checked = false,
  onChange,
}) => {
  return (
    <Container
      type={type}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
};

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
