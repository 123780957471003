import React from "react";
import Root from "containers/Root";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "styles/GlobalStyle";
import { BrowserRouter } from "react-router-dom";
import theme from "styles/themes";
import { AuthProvider } from "providers/AuthProvider";
import ModalProvider from "providers/Modal";
// import "semantic-ui-css/semantic.min.css";
const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ModalProvider>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </ModalProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
