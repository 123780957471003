import { useState, useEffect } from "react";
import app from "config/firebase";

const useAuth = () => {
  const fireUser = app.auth().currentUser;
  const [currentUser, setCurrentUser] = useState(fireUser);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unlisten = app.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setCurrentUser(authUser);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return {
    currentUser,
    loading,
  };
};

export default useAuth;
