import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import useConnect from "./connect";
import EditCategoryModal from "containers/Main/Categories/ModalEditCategory";
import { getThumbnailURL } from "helpers";
import TextTruncate from "react-text-truncate";

import {
  Card,
  CardTitle,
  Row,
  Section,
  Actions,
  ActionButton,
  CategoryTitle,
  CategoryWrapper,
  CardImage,
  CardWrapper,
  Price,
  CardDetailsWrapper,
  WrapperActions,
} from "./styles";
export default function OrderCategoriesList(props) {
  const { categoryId, categoryName, category } = props;
  const {
    storeId,
    valueProducts,
    loadingProducts,
    deleteProduct,
    editProduct,
    isModalOpen,
    toggleModal,
    itemList,
    onSortEnd,
    isChange,
    saveValue,
  } = useConnect({ categoryId, categoryName, category });
  const SortableItem = SortableElement(({ item }) => (
    <Card key={item.id}>
      <CardWrapper>
        {item.data().imgUrl && (
          <CardImage src={getThumbnailURL(item.data().imgUrl)} />
        )}
        <CardDetailsWrapper>
          <CardTitle>
            <TextTruncate line={2} element="span" text={item.data().name.es} />
          </CardTitle>
          <Price>{item.data().price}</Price>
        </CardDetailsWrapper>
      </CardWrapper>
      <Actions>
        <ActionButton
          variant="edit"
          onClick={() => editProduct(storeId, item.id, item)}
        />
        <ActionButton
          variant="trash"
          onClick={() => deleteProduct(storeId, item.id)}
        />
      </Actions>
    </Card>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <Row>
      {items.map((item, index) => (
        <SortableItem key={`${item.id}`} index={index} item={item} />
      ))}
    </Row>
  ));

  return (
    <>
      {loadingProducts && <div>Loading</div>}

      <Section>
        {valueProducts && (
          <>
            {isModalOpen && (
              <EditCategoryModal
                onRequestClose={toggleModal}
                category={category}
                storeId={storeId}
                categoryId={categoryId}
              />
            )}
            <CategoryWrapper>
              <CategoryTitle>{categoryName}</CategoryTitle>

              <WrapperActions>
                {isChange && (
                  <>
                    <span>Guarda los cambios realizados</span>
                    <ActionButton variant="save" onClick={saveValue} />
                  </>
                )}
                <ActionButton variant="edit" onClick={toggleModal} />
              </WrapperActions>
            </CategoryWrapper>

            <SortableList
              items={itemList}
              onSortEnd={onSortEnd}
              axis="y"
              helperclassName="SortableHelper"
              pressDelay={200}
            />
          </>
        )}
      </Section>
    </>
  );
}
