import React from "react";
import Component, { Icon } from "./styles";

const AddButton = ({ children, onClick, size, ...props }) => {
  return (
    <Component onClick={onClick} $size={size} {...props}>
      <Icon />
      {children}
    </Component>
  );
};

export default AddButton;
