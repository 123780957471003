import styled from "styled-components";
import DefaultButton from "components/Button";
import { P, H1 } from "components/Typography";
import DefaultInput from "components/Input";
import DefaultSwitch from "components/Switch";
import { from } from "styles/media";
import { QRCode as DefaultQRCode } from "react-qrcode-logo";
import DefaultColorPicker from "material-ui-color-picker";

export const Body = styled.div`
  padding: 2rem 1rem 7.6875rem 1rem;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const QRCode = styled(DefaultQRCode)``;

export const Button = styled(DefaultButton)`
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const Input = styled(DefaultInput)`
  margin-top: 1rem;
`;
export const Switch = styled(DefaultSwitch)``;

export const Text = styled(P)`
  max-width: 25.625rem;
`;
export const Title = styled(H1)`
  max-width: 25.625rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  ${from.tablet} {
    grid-template-columns: 21.875rem 1fr;
  }
  ${from.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const WrapperColors = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr 1fr;

  ${from.tablet} {
    grid-template-columns: 150px 150px;
  }
`;

export const WrapperQR = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 500px;
`;

export const ColorPicker = styled(DefaultColorPicker)`
  & .MuiInputBase-input {
    background-color: ${({ theme }) => theme.colors.gray2};
    border: 0.125rem solid ${({ theme }) => theme.colors.white};
    border-radius: 0.3125rem;
    padding: 0.875rem 0.75rem;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
`;
