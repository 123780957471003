import React from "react";
import useConnect from "./connect";
import { Subtitle, Button, Response, SuccessTitle } from "./styles";
import Layout from "components/Layout";
export default function Success() {
  const { sessionStripe, customerPortal } = useConnect();

  return (
    <Layout>
      <SuccessTitle>Su pago fue realizado con éxito</SuccessTitle>
      <Subtitle>{sessionStripe.subscription}</Subtitle>
      <Subtitle>{sessionStripe.customer}</Subtitle>
      <Button onClick={() => customerPortal(sessionStripe.id)}>
        Facturación
      </Button>
      <Response>{JSON.stringify(sessionStripe, null, 2)}</Response>
    </Layout>
  );
}
