import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import useConnect from "./connect";

import TextTruncate from "react-text-truncate";

import {
  Card,
  CardTitle,
  Row,
  Section,
  Title,
  AddButton,
  Actions,
  ActionButton,
} from "./styles";
export default function OrderCategoriesList(props) {
  const {
    deleteCategoty,
    data,
    itemList,
    onSortEnd,
    goToAddCategory,
    goToEditCategory,
  } = useConnect();
  const SortableItem = SortableElement(({ item }) => {
    return (
      <Card key={item.id}>
        <CardTitle>
          <TextTruncate line={2} element="span" text={item.data().title.es} />
        </CardTitle>
        <Actions>
          <ActionButton
            variant="edit"
            onClick={() => goToEditCategory(item.id)}
          />
          <ActionButton
            variant="trash"
            onClick={() => deleteCategoty(item.id)}
          />
        </Actions>
      </Card>
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <Row>
      {items.map((item, index) => (
        <SortableItem key={`${item.id}`} index={index} item={item} />
      ))}
    </Row>
  ));

  return (
    <Section>
      <Row>
        <Title>Categorías</Title>
      </Row>

      <AddButton onClick={goToAddCategory} size="big">
        Añadir Categoría
      </AddButton>
      {data && (
        <>
          <SortableList
            items={itemList}
            onSortEnd={onSortEnd}
            axis="y"
            helperclassName="SortableHelper"
            pressDelay={200}
          />
        </>
      )}
    </Section>
  );
}
