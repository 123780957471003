import styled from "styled-components";
import DefaultButton from "components/Button";
import { H3 } from "components/Typography";

export const Button = styled(DefaultButton)`
  margin-top: 1.875rem;
`;

export const Section = styled.section`
  width: 100%;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px;
`;

export const Title = styled(H3)`
  margin-bottom: 2rem;
`;
