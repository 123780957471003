import styled, { css } from "styled-components";
import addAlpha from "utils/addAlpha";
import { NavLink as NavLinkDefault } from "react-router-dom";

export const ExtraInfo = styled.span``;

export const NavLink = styled(NavLinkDefault)`
  padding: 0.9063rem 1.5rem;
  border-radius: 1rem;
  text-decoration: none;
  background-color: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.navyGreen};
  ${ExtraInfo} {
    color: ${({ theme }) => addAlpha(theme.colors.navyGreen, 0.6)};
  }
  &.selected {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.corporate};
    background-color: ${({ theme }) => theme.colors.white};
    ${ExtraInfo} {
      color: ${({ theme }) => theme.colors.corporate};
    }
  }

  ${({ $size }) => {
    switch ($size) {
      case "medium":
      default:
        return css`
          font-size: 1.1875rem;
          line-height: 1.6875rem;
        `;

      case "big":
        return css`
          font-size: 1.3125rem;
        `;
    }
  }}
`;
