import { useCallback } from "react";

const useLogic = ({ defaultOnClick }) => {
  const onClick = useCallback(
    (event) => {
      if (defaultOnClick) {
        event.preventDefault();
        event.stopPropagation();

        defaultOnClick(event);
      }
    },
    [defaultOnClick]
  );

  return {
    onClick,
  };
};

export default useLogic;
