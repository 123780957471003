import React from "react";
import Dialog from "@material-ui/core/Dialog";

import {
  Fade,
  Container,
  Header,
  HeaderContent,
  Button,
  CloseIcon,
  Title,
  EmptyBox,
  Content,
} from "./styles";

const ModalScreen = ({
  children,
  title,
  handleClose,
  open,
  showCloseButtonHeader = true,
  ...props
}) => {
  return (
    <Dialog fullScreen open={open} onClose={handleClose} {...props}>
      <Container>
        <Fade in={open}>
          <>
            <Header>
              <HeaderContent>
                {showCloseButtonHeader ? (
                  <Button onClick={handleClose}>
                    <CloseIcon />
                  </Button>
                ) : (
                  <div />
                )}

                <Title>{title}</Title>
                <EmptyBox />
              </HeaderContent>
            </Header>
            <Content>{children}</Content>
          </>
        </Fade>
      </Container>
    </Dialog>
  );
};

export default ModalScreen;
