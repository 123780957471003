import { ErrorMessage, useField } from "formik";
import React, { memo } from "react";

import {
  Container,
  Error,
  Label,
  Component,
  SelectContainer,
  Icon,
  HelperText,
} from "./styles";

const Select = ({ label, helper, disabled, children, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  const filled = field.value !== "";

  return (
    <Container>
      {label && <Label $disabled={disabled}>{label}</Label>}
      <SelectContainer>
        <Component
          {...field}
          {...props}
          id={field.name}
          $error={hasError}
          $filled={filled}
          disabled={disabled}
        >
          {children}
        </Component>
        <Icon $disabled={disabled} />
      </SelectContainer>
      {!hasError && <HelperText $disabled={disabled}>{helper}</HelperText>}
      <ErrorMessage component={Error} name={field.name} />
    </Container>
  );
};

export default memo(Select);
