import React from "react";
import { Formik, Field, FieldArray } from "formik";
import {
  Row,
  Form,
  Input,
  Button,
  Allergen,
  PriceWrapper,
  ButtonPrice,
  AllergenWrapper,
  TextArea,
  Select,
  ButtonRounded,
  LabelSwitch,
  Switch,
  CheckboxWrapper,
  IconPlus,
} from "./styles";
import { validationSchema } from "./constants";
import useConnect from "./connect";
import ModalScreen from "components/ModalScreen";
import ImageUploaded from "components/ImageUpload";
import Checkbox from "components/forms/Checkbox";
import alergenos from "constants/alergenos";
export default function CreateStore() {
  const { handleSubmit, initialValues, handleClose, options } = useConnect();

  return (
    <>
      <ModalScreen title="Crear producto" open handleClose={handleClose}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form noValidate>
              <Field
                name="category"
                as={Select}
                label="Categoría"
                helper="Texto de ayuda"
              >
                {options.map((option, i) => {
                  return (
                    <option value={option.value} key={i}>
                      {option.label}
                    </option>
                  );
                })}
              </Field>
              <Row>
                <Field
                  type="text"
                  name="name.es"
                  as={Input}
                  label="Nombre 🇪🇸"
                />
                <Field
                  type="text"
                  name="name.en"
                  as={Input}
                  label="Nombre 🇺🇸 "
                />
              </Row>
              <Row>
                <Field
                  type="text"
                  name="description.es"
                  as={TextArea}
                  label="Descripción 🇪🇸"
                />
                <Field
                  type="text"
                  name="description.en"
                  as={TextArea}
                  label="Descripción 🇺🇸"
                />
              </Row>

              <Row>
                <Field type="text" name="price" as={Input} label="Precio" />
                <FieldArray
                  name="prices"
                  render={(arrayHelpers) => (
                    <div>
                      {values.prices && values.prices.length > 0 ? (
                        values.prices.map((price, index) => (
                          <PriceWrapper key={index}>
                            <Field
                              type="text"
                              name={`prices.${index}.name`}
                              as={Input}
                              label="Variante"
                            />
                            <Field
                              type="text"
                              isMoney
                              name={`prices.${index}.price`}
                              as={Input}
                              label="Precio"
                            />
                            <ButtonRounded
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </ButtonRounded>
                            <ButtonRounded
                              type="button"
                              onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                            >
                              +
                            </ButtonRounded>
                            {/* <Actions>
                              
                              <ButtonRounded
                                type="button"
                                onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                              >
                                +
                              </ButtonRounded>
                            </Actions> */}
                          </PriceWrapper>
                        ))
                      ) : (
                        <ButtonPrice
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                          icon={<IconPlus />}
                          iconPosition="left"
                        >
                          Precios
                        </ButtonPrice>
                      )}
                    </div>
                  )}
                />
              </Row>

              <CheckboxWrapper>
                <LabelSwitch
                  control={
                    <Field name="featured" as={Switch} type="checkbox" />
                  }
                  label="Artículo destacado"
                />
                <LabelSwitch
                  control={
                    <Field name="takeaway" as={Switch} type="checkbox" />
                  }
                  label="Disponible para llevar"
                />
                <LabelSwitch
                  control={<Field name="hidden" as={Switch} type="checkbox" />}
                  label="No disponible"
                />
              </CheckboxWrapper>

              <AllergenWrapper>
                <Row>
                  {alergenos.map((item) => (
                    <Allergen key={item.name}>
                      <Field
                        type="checkbox"
                        name="allergens"
                        as={Checkbox}
                        value={item.name}
                      />
                      <img src={item.image} width="18" alt={item.label} />{" "}
                      {item.label}
                    </Allergen>
                  ))}
                </Row>
              </AllergenWrapper>

              <ImageUploaded
                setFieldValue={setFieldValue}
                values={values}
                name="imgUrl"
                label="Imagen del producto"
                folder="bg"
              />

              <Button variant="primary" fullWidth type="submit">
                Guardar
              </Button>
            </Form>
          )}
        </Formik>
      </ModalScreen>
    </>
  );
}
