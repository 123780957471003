import { useCallback, useMemo } from "react";
import app, { db } from "config/firebase";
import { useHistory, useParams } from "react-router-dom";
import {
  useDocumentDataOnce,
  useCollectionOnce,
} from "react-firebase-hooks/firestore";

const useConnect = () => {
  const history = useHistory();
  const { storeId, productId } = useParams();
  const [value, loading, error] = useDocumentDataOnce(
    db.collection("stores").doc(storeId).collection("products").doc(productId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const initialValues = useMemo(
    () => ({
      name: {
        es: value?.name.es || "",
        en: value?.name.en || "",
      },
      description: {
        es: value?.description.es || "",
        en: value?.description.en || "",
      },
      category: value?.category || "",
      price: value?.price || "",
      prices: value?.prices || [],
      allergens: value?.allergens || [],
      featured: value?.featured || false,
      hidden: value?.hidden || false,
      takeaway: value?.takeaway || false,
      imgUrl: value?.imgUrl || "",
    }),
    [value]
  );

  const [
    valueCategories,
    loadingCategories,
    errorCategories,
  ] = useCollectionOnce(
    db.collection("stores").doc(storeId).collection("categories"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const options = useMemo(() => {
    const newOptions = [];
    newOptions.push({ label: "Selecciona una categoría", value: "" });
    valueCategories &&
      valueCategories.docs.map((category) => {
        newOptions.push({
          label: category.data().title.es,
          value: category.id,
        });
        return null;
      });

    return newOptions;
  }, [valueCategories]);

  const handleCreateProduct = useCallback(
    async (values) => {
      try {
        // async magic goes here...
        db.collection("stores")
          .doc(storeId)
          .collection("products")
          .doc(productId)
          .update({
            ...values,
            editedAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
          });

        history.push(`/store/${storeId}/view`);
      } catch (error) {
        alert(error);
      }
    },
    [history, storeId, productId]
  );

  const handleClose = useCallback(() => {
    history.push(`/store/${storeId}/view`);
  }, [history, storeId]);

  const handleSubmit = useCallback(
    async (values) => {
      await handleCreateProduct(values);
      // handleClose();
    },
    [handleCreateProduct]
  );

  return {
    handleSubmit,
    initialValues,
    valueCategories,
    loadingCategories,
    errorCategories,
    handleClose,
    options,
    loading,
    error,
    value,
  };
};

export default useConnect;
