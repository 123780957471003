import { useState, useCallback } from "react";
import app from "config/firebase";
import { useHistory } from "react-router-dom";

const useConnect = () => {
  const history = useHistory();
  const logout = useCallback(async () => {
    await app.auth().signOut();
    history.push("/");
  }, [history]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = useCallback(() => logout(), [logout]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const actions = [
    { label: "Mis cartas", to: "/home" },
    { label: "Cerrar sesión", onClick: handleLogout },
  ];

  return {
    handleClick,
    handleClose,
    open,
    id,
    actions,
    anchorEl,
  };
};

export default useConnect;
