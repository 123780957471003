import React, { useRef, useState } from "react";
import { Input, CopyIon, Button, Wrapper, Caption } from "./styles";
const CopyButton = ({ text }) => {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copiado!");
  }

  return (
    <div>
      {
        /* Logical shortcut for only displaying the 
          button if the copy command exists */
        document.queryCommandSupported("copy") && (
          <Wrapper>
            <Button onClick={copyToClipboard}>
              <CopyIon />
            </Button>
            <Caption>{copySuccess}</Caption>
          </Wrapper>
        )
      }
      <form>
        <Input ref={textAreaRef} value={text} disabled />
      </form>
    </div>
  );
};

export default CopyButton;
