import styled from "styled-components";
import { Link as DefaultLink } from "react-router-dom";

import GuestBackground from "assets/images/background.jpg";
import { H2, Small } from "components/Typography";
import { from } from "styles/media";
import DefaultLogo from "components/LogoSimbolo";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${from.mobile} {
    flex-direction: row;
  }
`;
export const Logo = styled(DefaultLogo)`
  ${from.mobile} {
    margin-top: 1.25rem;
  }
`;

export const Cover = styled.div`
  align-items: center;

  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 40.8125rem;
  padding: 1.25rem 1.25rem 3.25rem 1.25rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.corporate};
  background-image: url(${GuestBackground});
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: -40px;

  ${from.mobile} {
    background-size: cover;
    background-position: 25%;
    flex: 1.5;
    padding: 0;

    & > *:not(:last-child) {
      padding: 1.1875rem 1rem;
    }
  }
`;

export const CoverContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  ${from.mobile} {
    max-width: 23.9375rem;
  }
`;

export const View = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: auto;
  padding: 2.5rem 1.25rem;

  ${from.mobile} {
    flex: 2;
    padding: 0;
  }
`;

export const ViewContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${from.mobile} {
    margin-top: 14.375rem;
    max-width: 22.5rem;
  }
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  margin-bottom: 3.125rem;
  text-decoration: none;

  ${from.mobile} {
    margin-bottom: 10rem;
  }
`;

export const Title = styled(H2)`
  margin-bottom: 1rem;
  max-width: 19.9375rem;

  ${from.mobile} {
    max-width: unset;
  }
`;

export const Text = styled(Small)`
  max-width: 15.4375rem;

  ${from.mobile} {
    max-width: unset;
  }
`;
