import React from "react";
import { NavLink, ExtraInfo } from "./styles";

export const NotRoutedTab = ({ label, size, selected, ...props }) => {
  return (
    <NavLink
      as="div"
      $size={size}
      {...props}
      className={selected ? "selected" : undefined}
    >
      {label}
    </NavLink>
  );
};

const Tab = ({ label, extraInfo, size, ...props }) => {
  return (
    <NavLink $size={size} activeClassName="selected" {...props}>
      {label} <ExtraInfo>{extraInfo}</ExtraInfo>
    </NavLink>
  );
};

export default Tab;
