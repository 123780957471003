import styled from "styled-components";
import { Link as DefaultLink } from "react-router-dom";

export const Link = styled(DefaultLink)``;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  box-shadow: rgb(70 64 223 / 20%) 0px 3px 10px;
  background-color: ${({ theme }) => theme.colors.black};
`;
