import React from "react";
import app, { db } from "config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import Layout from "components/Layout";
import { Section, Button, Title } from "./styles";
import useConnect from "./connect";
import CardStore from "components/CardStore";
import CreateStoreModal from "containers/Main/Store/Create";
// import SubscribeToProduct from "components/SubscribeToProduct";
export default function Home() {
  const {
    goToStore,
    open,
    handleOpenCreateStoreModal,
    handleCloseCreateStoreModal,
  } = useConnect();
  const [currentUser] = useAuthState(app.auth());

  const [value, loading, error] = useCollection(
    db.collection("stores").where("uid", "==", currentUser.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <>
      <CreateStoreModal
        title="Crear una Carta"
        open={open}
        handleClose={handleCloseCreateStoreModal}
      />
      <Layout>
        <Title>Mis Productos</Title>
        {/* <SubscribeToProduct /> */}
        <div>
          {error && <strong>Error: {JSON.stringify(error)}</strong>}
          {loading && <div>Loading</div>}
          {value && (
            <Section>
              {value.empty && (
                <div>
                  Aun no has creado ninguna carta, ¿quieres crearla ahora?.
                </div>
              )}

              {value.docs.map((doc) => (
                <CardStore
                  key={doc.id}
                  logo={doc.data().logo}
                  title={doc.data().name}
                  description={doc.data().description}
                  createdAt={doc.data().createdAt}
                  onClick={() => goToStore(doc.id)}
                />
              ))}
            </Section>
          )}
        </div>
        <Button onClick={handleOpenCreateStoreModal} variant="tertiary">
          Crear Carta
        </Button>
      </Layout>
    </>
  );
}
