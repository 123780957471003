import React, { useState } from "react";
import {
  Container,
  IconCamera,
  ProgressBar,
  FillBar,
  Wrapper,
  Text,
  Image,
  ActionButton,
  WrapperImage,
  HelpText,
} from "./styles";
// import useLogic from "./logic";
import { storage } from "config/firebase";
import { useDropzone } from "react-dropzone";
import { getFileExtension, getThumbnailURL } from "helpers";
import { v4 as uuidv4 } from "uuid";
import { useField, ErrorMessage } from "formik";

const ImageUploaded = ({ setFieldValue, values, name, label, ...props }) => {
  const [field, meta] = useField(name);
  const hasError = !!(meta.touched && meta.error);

  //   const { onClick } = useLogic({ defaultOnClick });
  const [status, setStatus] = useState(false);
  const [imageProgress, setImageProgress] = useState("");
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      var extImg = getFileExtension(acceptedFiles[0].name);
      return new Promise(function (resolve, reject) {
        const storageRef = storage.ref(`/images/test/${uuidv4()}.${extImg}`);
        const uploadTask = storageRef.put(acceptedFiles[0]);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageProgress(progress);
          },
          function error(err) {
            console.log("error", err);
            reject();
          },
          function complete() {
            setStatus(true);
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                setTimeout(function () {
                  setStatus(false);
                  setFieldValue(name, downloadURL);
                  resolve(downloadURL);
                }, 5000);
              });
          }
        );
      });
    },
    multiple: false,
    nodrag: true,
  });

  return (
    <div>
      {values[name] && values[name].length > 0 ? (
        <Container>
          <WrapperImage>
            <Image src={getThumbnailURL(values[name])} width="300" />
            <ActionButton
              variant="trash"
              onClick={() => setFieldValue(name, "")}
            />
          </WrapperImage>
        </Container>
      ) : (
        <>
          <Container
            {...getRootProps({ className: "dropzone" })}
            $error={hasError}
          >
            <input {...getInputProps()} />
            <Wrapper>
              <IconCamera />
              <Text>Subir {label}</Text>
            </Wrapper>
          </Container>
          <ProgressBar>
            <FillBar progress={imageProgress} />
          </ProgressBar>
          {status && <HelpText>Gererando miniaturas...</HelpText>}
        </>
      )}
      <ErrorMessage component={Error} name={field.name} />
    </div>
  );
};

export default ImageUploaded;

// const UploadComponent = (props) => {};
