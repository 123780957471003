import { useCallback } from "react";
import app, { db } from "config/firebase";
import { useHistory, useParams } from "react-router-dom";

const useConnect = () => {
  const history = useHistory();
  const { storeId, categoryId } = useParams();
  const initialValues = {
    title: {
      es: "",
      en: "",
    },
  };

  const handleCreateCategory = useCallback(
    async (values) => {
      try {
        await db
          .collection("stores")
          .doc(storeId)
          .collection("categories")
          .doc(categoryId)
          .set(
            {
              ...values,
              createdAt: app.firebase_.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          );
      } catch (error) {
        alert(error);
      }
    },
    [categoryId, storeId]
  );

  const handleClose = useCallback(() => {
    history.push(`/store/${storeId}/categories`);
  }, [history, storeId]);

  const handleSubmit = useCallback(
    async (values) => {
      await handleCreateCategory(values);
      setTimeout(() => {
        history.push(`/store/${storeId}/categories`);
      }, 2000);
    },
    [handleCreateCategory, history, storeId]
  );

  return {
    // loadOptions,
    handleSubmit,
    initialValues,
    handleClose,
  };
};

export default useConnect;
