import React from "react";
import { Component, Title, Image, Wrapper, Date } from "./styles";
import { getThumbnailURL } from "helpers";

const CardStore = ({ logo, title, createdAt, onClick }) => {
  return (
    <Component onClick={onClick}>
      {logo && <Image src={getThumbnailURL(logo)} />}
      <Wrapper>
        <Title>{title}</Title>
        <Date>
          {createdAt && createdAt.toDate().toLocaleDateString("es-ES")}
        </Date>
      </Wrapper>
    </Component>
  );
};

export default CardStore;
