import altramuces from "../assets/iconos/altramuces.png";
import apio from "../assets/iconos/apio.png";
import cacahuetes from "../assets/iconos/cacahuetes.png";
import crustaceos from "../assets/iconos/crustaceos.png";
import dioxido from "../assets/iconos/dioxito-azufre-sultifos.png";
import gluten from "../assets/iconos/gluten.png";
import huevos from "../assets/iconos/huevos.png";
import pescado from "../assets/iconos/pescado.png";
import soja from "../assets/iconos/soja.png";
import lacteos from "../assets/iconos/lacteos.png";
import frutos_cascara from "../assets/iconos/frutos_cascara.png";
import mostaza from "../assets/iconos/mostaza.png";
import sesamo from "../assets/iconos/sesamo.png";
import moluscos from "../assets/iconos/moluscos.png";

const alergenos = [
    {
      name: 'gluten',
      label: 'Cereales que contengan gluten',
      image: gluten
    },
    {
      name: 'crustaceos',
      label: 'Crustáceos y productos a base de crustáceos',
      image: crustaceos
    },
    {
      name: 'huevos',
      label: 'Huevos y productos a base de huevo',
      image: huevos
    },
    {
      name: 'pescado',
      label: 'Pescado y productos a base de pescado',
      image: pescado
    },
    {
      name: 'altramuces',
      label: 'Altramuces y productos a base de altramuces',
      image: altramuces
    },
    {
      name: 'apio',
      label: 'Apio y productos derivados',
      image: apio
    },
    {
      name: 'cacahuetes',
      label: 'Cacahuetes y productos a base de cacahuetes',
      image: cacahuetes
    },
    {
      name: 'soja',
      label: 'Soja y productos a base de soja',
      image: soja
    },
    {
      name: 'dioxito-azufre-sultifos',
      label: 'Dióxido de azufre y sulfitos',
      image: dioxido
    },
    {
      name: 'lacteos',
      label: 'Leche y sus derivados',
      image: lacteos
    },
    {
      name: 'frutos_cascara',
      label: 'Frutos de cáscara',
      image: frutos_cascara
    },
    {
      name: 'mostaza',
      label: 'Mostaza y productos derivados',
      image: mostaza
    },
    {
      name: 'sesamo',
      label: 'Granos de sésamo y productos a base de granos de sésamo',
      image: sesamo
    },
    {
      name: 'moluscos',
      label: 'Moluscos y productos a base de moluscos',
      image: moluscos
    },
  ];
  
  export default alergenos;