import DefaultSwitch from "@material-ui/core/Switch";
import styled from "styled-components";
import addAlpha from "utils/addAlpha";

const Switch = styled(DefaultSwitch)`
  &.MuiSwitch-root {
    width: 4.25rem;
    height: 3rem;
  }

  .MuiSwitch-track {
    height: 1.25rem;
    width: 3rem;
    border-radius: 1.125rem;
  }

  .MuiSwitch-thumb {
    height: 1.75rem;
    width: 1.75rem;
    box-shadow: none;
  }

  .MuiSwitch-colorSecondary {
    color: #5f6764;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.greenDark};
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.greenMedium};
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.greenMedium, 0.1)};
  }

  .MuiSwitch-track {
    color: ${({ theme }) => theme.colors.greenMedium};
  }

  .MuiSwitch-colorSecondary.Mui-disabled {
    color: #acb0af;
  }
  .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 1;
    background-color: #d3d4d4;
  }

  .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled {
    color: #91d1ba;
  }
  .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled + .MuiSwitch-track {
    background-color: #9bdfc6;
  }
  .MuiIconButton-label {
    left: -28px;
    top: -2px;
    position: relative;
  }
`;

export default Switch;
