import React from "react";
import Header from "../Header";
import styled from "styled-components";
import { from } from "styles/media";

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <Container fluid style={{ padding: "0.5em 2em" }}>
        {children}
      </Container>
    </>
  );
}

const Container = styled.main`
  padding: 1.5625rem 1.25rem !important;
  ${from.tablet} {
    padding: 3rem !important;
  }
  display: block;
`;
