import { useHistory, useParams } from "react-router-dom";

const useConnect = () => {
  const { storeId } = useParams();
  const history = useHistory();
  const goToEdit = (storeId) => {
    history.push(`/store/${storeId}/edit`);
  };

  return {
    goToEdit,
    storeId,
  };
};

export default useConnect;
