import styled from "styled-components";
import DefaultButton from "components/Button";
import { H3 } from "components/Typography";
import { from } from "styles/media";

export const Button = styled(DefaultButton)``;

export const Section = styled.section`
  width: 100%;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: 20px;
  display: block;
`;

export const Row = styled.div`
  margin-bottom: 1rem !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Breadcrumbbar = styled.div`
  padding: 0;
  border-radius: 3px;
`;
export const WidgetBar = styled.div`
  /* float: right; */
`;

export const Col4 = styled.div`
  display: flex;
  align-items: center;
`;
export const Title = styled(H3)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  ${from.tablet} {
    margin-left: 20px;
  }
`;
export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  ${from.tablet} {
    width: 120px;
    height: 120px;
  }
`;
