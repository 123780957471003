import styled, { css } from "styled-components";

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled.button`
  outline: 0;
  cursor: pointer;
  font-size: 1.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  :disabled {
    color: ${({ theme }) => theme.colors.gray4};
  }
  ${({ $variant }) => {
    switch ($variant) {
      case "delete":
        return css`
          border-radius: 1rem;
          width: 3.5rem;
          height: 3.5rem;
          color: ${({ theme }) => theme.colors.redMedium};
          :hover:not([disabled]) {
            color: ${({ theme }) => theme.colors.redLight};
          }
          :active:not([disabled]) {
            color: ${({ theme }) => theme.colors.redDark};
          }
        `;
      case "save":
        return css`
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          padding-top: 0.4rem;
          color: ${({ theme }) => theme.colors.navyGreen};
          :hover:not([disabled]) {
            color: ${({ theme }) => theme.colors.greenMedium};
          }
          :active:not([disabled]) {
            color: ${({ theme }) => theme.colors.greenDark};
          }
        `;
      case "print":
      default:
        return css`
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          padding-top: 0.4rem;
          color: ${({ theme }) => theme.colors.navyGreen};
          :hover:not([disabled]) {
            color: ${({ theme }) => theme.colors.greenMedium};
          }
          :active:not([disabled]) {
            color: ${({ theme }) => theme.colors.greenDark};
          }
        `;
    }
  }}
`;
export default ActionButton;
