import React from "react";
import { Form, Formik, Field } from "formik";
import useConnect from "./connect";
import { initialValues, validationSchema } from "./constants";

import {
  Container,
  Title,
  Subtitle,
  Input,
  FormButton,
  ResetContainer,
  ResetLink,
  ResetText,
  OtherAction,
  Caption,
  SecondaryButton,
  GoogleButton,
  IconGoogle,
} from "./styles";

const Login = () => {
  const {
    handleRegisterRedirect,
    handleSubmit,
    loginWithGoogle,
  } = useConnect();

  return (
    <Container>
      <Title>Iniciar sesión en nowEat</Title>
      <Subtitle></Subtitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Field as={Input} label="Email" name="email" type="email" />
            <Field
              as={Input}
              label="Password"
              name="password"
              type="password"
            />
            <FormButton disabled={isSubmitting} type="submit">
              Iniciar sesión
            </FormButton>
            <GoogleButton
              onClick={loginWithGoogle}
              iconPosition="left"
              icon={<IconGoogle />}
            >
              Login google
            </GoogleButton>
          </Form>
        )}
      </Formik>
      <ResetContainer>
        <ResetText>Necesitas ayuda?</ResetText>
        <ResetLink to="/reset-password">Restablecer contraseña</ResetLink>
      </ResetContainer>
      <OtherAction>
        <Caption>¿Aun no tienes cuenta?</Caption>
        <SecondaryButton onClick={handleRegisterRedirect}>
          Crear cuenta
        </SecondaryButton>
      </OtherAction>
    </Container>
  );
};

export default Login;
