import styled from "styled-components";
import { Body, H1, H3, Subtitle } from "components/Typography";
import DefaultAddButton from "components/AddButton";
import DefaultActionButton from "components/ActionButton";
import { FiTrash } from "react-icons/fi";
export const Section = styled.section`
  width: 100%;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: 20px;
`;
export const Title = styled(H1)``;
export const CategoryTitle = styled(H3)``;
export const AddButton = styled(DefaultAddButton)`
  margin-bottom: 40px;
`;

export const Row = styled.div``;

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  margin-bottom: 1.25rem;
  /* padding: 0 30px; */
  &:active {
    cursor: grabbing;
  }
  &:hover {
    box-shadow: 0rem 0.5rem 1rem rgb(35 35 35 / 2%),
      0rem 0.5rem 2rem rgb(35 35 35 / 2%);
  }
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border 0.1s ease, transform 0.1s ease;
`;

export const CardTitle = styled(Subtitle)``;

export const CardImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 0.75rem;
  object-fit: cover;
`;
export const Actions = styled.div`
  display: flex;
  margin-right: 30px;
`;

export const IconTrash = styled(FiTrash)``;

export const ActionButton = styled(DefaultActionButton)`
  :not(:last-child) {
    margin-right: 0.625rem;
  }
`;

export const CategoryWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CardDetailsWrapper = styled.div`
  margin-left: 30px;
`;

export const Price = styled(Body)``;

export const WrapperActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
