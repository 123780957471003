import { db } from "config/firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

const useConnect = () => {
  const { storeId } = useParams();

  const [valueStore, loadingStore, errorStore] = useDocument(
    db.doc(`stores/${storeId}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return {
    valueStore,
    loadingStore,
    errorStore,
    storeId,
  };
};

export default useConnect;
