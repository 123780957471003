import styled from "styled-components";
import DefaultButton from "components/Button";
import DefaultInput from "components/Input";
import { H2, P, Body } from "components/Typography";
import { from } from "styles/media";

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled(H2)`
  margin-bottom: 0.5rem;

  ${from.laptop} {
    font-size: 1.75rem;
    font-weight: ${({ theme }) => theme.weights.bold};
    line-height: 2.4375rem;
  }
`;

export const Subtitle = styled(Body)`
  margin-bottom: 2.25rem;
`;

export const Input = styled(DefaultInput)`
  width: 100%;
`;

export const FormButton = styled(DefaultButton).attrs(() => ({
  fullWidth: true,
  variant: "primary",
}))`
  margin-bottom: 1rem;
`;

export const OtherAction = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
`;

export const Caption = styled(P)`
  color: ${({ theme }) => theme.colors.bluewood};
`;

export const SecondaryButton = styled(DefaultButton).attrs(() => ({
  variant: "tertiary",
}))``;

export const Policy = styled(P)`
  color: ${({ theme }) => theme.colors.starDust};
  margin-right: 0.75rem;
  font-size: 0.75rem;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.bluewood};
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-decoration: none;
`;
