import * as yup from "yup";

export const initialValues = {
  email: "",
  password: "",
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email no es válido!")
    .required("Email es requerido!"),
  password: yup.string().required("Password es requerido!"),
});
