export function truncate(str) {
  return str.length > 10 ? str.substring(0, 7) + "..." : str;
}

export function getThumbnailURL(url, suffix = "_500x500") {
  const uriIndex = url.indexOf("?alt=media&token");
  const insertIndex = url.lastIndexOf(".", uriIndex);
  return url.slice(0, insertIndex) + suffix + url.slice(insertIndex);
}
export function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function generateId() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
}

export const copyToClipboard = (text) => {
  const ta = document.createElement("textarea");
  ta.innerText = text;
  document.body.appendChild(ta);
  ta.select();
  document.execCommand("copy");
  ta.remove();
};
