import DefaultFade from "@material-ui/core/Fade";
import styled from "styled-components";
import { from } from "styles/media";
import { FiX } from "react-icons/fi";
import { H2 } from "components/Typography";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.gray1};
  min-height: 100%;
`;

export const Fade = styled(DefaultFade)`
  :focus {
    outline: none;
  }
`;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 74rem;
`;

export const Header = styled.header`
  background: ${({ theme }) => theme.colors.gray1};
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
  position: fixed;
  width: 100%;
  z-index: 2;
`;

export const HeaderContent = styled(MaxWidth)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.5625rem 1.25rem;
  ${from.tablet} {
    padding: 1.5625rem 0;
  }
`;

export const Title = styled(H2)``;

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.gray6};
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const CloseIcon = styled(FiX)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const EmptyBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Content = styled(MaxWidth)`
  margin-top: 7rem;
  padding: 0 1.25rem;
  ${from.tablet} {
    padding: 0;
  }
`;
