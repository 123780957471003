import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { Link as DefaultLink } from 'react-router-dom';
import DefaultButton from 'components/Button';
import DefaultInput from 'components/Input';
import { H2, Body } from 'components/Typography';
import { BiArrowBack as ArrowLeft } from 'react-icons/bi';
import { from } from 'styles/media';

export const Container = styled.div``;

export const Title = styled(H2)`
  margin-bottom: 0.75rem;

  ${from.laptop} {
    font-size: 1.75rem;
    font-weight: ${({ theme }) => theme.weights.bold};
    line-height: 2.4375rem;
  }
`;

export const Subtitle = styled(Body)`
  margin-bottom: 1.625rem;
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;

export const Input = styled(DefaultInput)`
  width: 100%;
`;

export const Button = styled(DefaultButton).attrs(() => ({
  fullWidth: true,
  variant: 'primary',
}))`
  margin-bottom: 1rem;
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.bluewood};
  font-size: 1rem;
  line-height: 1.375rem;
  text-decoration: none;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BackIcon = styled(ArrowLeft)`
  margin-right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
`;

export const Text = styled(Body)``;
