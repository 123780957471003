import styled from "styled-components";
import DefaultButton from "components/Button";
import { P } from "components/Typography";
import DefaultInput from "components/Input";
import { Form as FormikForm } from "formik";
import { from } from "styles/media";
import DefaultTextArea from "components/TextArea";
import DefaultSelect from "components/Select";
import DefaultSwitch from "components/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FiPlus } from "react-icons/fi";

export const Body = styled.div`
  padding: 2rem 1rem 7.6875rem 1rem;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(DefaultButton)`
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const ButtonPrice = styled(DefaultButton)`
  margin-top: 43px;
`;

export const ButtonRounded = styled(DefaultButton)`
  width: 40px;
  height: 40px;
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const Input = styled(DefaultInput)`
  margin-top: 1rem;
`;

export const Select = styled(DefaultSelect)`
  margin-top: 1rem;
`;

export const TextArea = styled(DefaultTextArea)`
  margin-top: 1rem;
`;

export const LabelSwitch = styled(FormControlLabel)`
  margin-top: 1rem;
`;

export const Switch = styled(DefaultSwitch)``;

export const Text = styled(P)`
  max-width: 25.625rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  ${from.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const RowPrice = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr 200px;
  width: 100%;
  align-items: baseline;
`;

export const PriceWrapper = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr 1fr 40px 40px;
  width: 100%;
`;

export const AllergenWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 0.3125rem;
  margin-top: 30px;
`;

export const Allergen = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  margin-left: 30px;
  align-items: center;
`;

export const IconPlus = styled(FiPlus)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.white};
`;
