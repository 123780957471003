import styled from 'styled-components';
import Menu from '@material-ui/core/Popover';
import OptionLink from 'components/Option';

export const DropContainer = styled(Menu)`
  & .MuiPopover-paper {
    min-width: 13.75rem;
  }
`;

export const Item = styled(OptionLink)`
  margin-top: 0.5rem;
  :last-child {
    margin-bottom: 0.5rem;
  }
`;
