import styled from "styled-components";
import DefaultTab from "components/Tab";

export const Container = styled.div`
  height: fit-content;
`;

export const Tabs = styled.nav`
  display: flex;
  margin-bottom: 1.5rem;
`;

export const Tab = styled(DefaultTab)`
  :not(:last-child) {
    margin-right: 1rem;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 2rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;
