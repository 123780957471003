import * as yup from "yup";

/* eslint-disable import/prefer-default-export */

export const validationSchema = yup.object().shape({
  name: yup.object().shape({
    es: yup.string().required("Debes introducir el nombre del producto."),
    en: yup.string().required("Debes introducir el nombre del producto [en]."),
  }),
  category: yup.string().required("Debes introducir una categoría."),
});
