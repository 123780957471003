export const HOME = "/home";
export const SIGN_UP = "/register";
export const SIGN_IN = "/";
export const PASSWORD_FORGET = "/reset-password";
export const VIEW_STORE = "/store/:storeId/view";
export const EDIT_STORE = "/store/:storeId/edit";
export const STORE = "/store/:storeId";
export const QR_STORE = "/store/:storeId/qr";
export const CREATE_STORE = "/store/create";
export const CREATE_PRODUCT = "/store/:storeId/product/create";
export const EDIT_PRODUCT = "/store/:storeId/product/:productId/edit";
export const ORDER_PRODUCTS = "/store/:storeId/order-products/:categoryId";
export const CREATE_CATEGORY = "/store/:storeId/category/create";
export const ORDER_CATEGORIES = "/store/:storeId/categories";
export const EDIT_CATEGORY = "/store/:storeId/categories/:categoryId/edit";
// export const ORDER_CATEGORIES = "/store/:storeId/categories/order";
export const SUCCESS = "/payment/success";
