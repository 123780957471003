import { useState, useEffect, useMemo, useCallback } from "react";
import { db } from "config/firebase";
import { useParams, useHistory } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import arrayMove from "array-move";
import { confirmAlert } from "react-confirm-alert";

const useConnect = ({ categoryId, categoryName, category }) => {
  let history = useHistory();
  const [itemList, setItemList] = useState([]);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const { storeId } = useParams();
  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };
  const query = useMemo(() => {
    const messagesRef = db
      .collection("stores")
      .doc(storeId)
      .collection("products")
      .where("category", "==", categoryId)
      .orderBy("order");
    const query = messagesRef;
    return query;
  }, [storeId, categoryId]);

  const [valueProducts, loadingProducts, errorProduts] = useCollection(query);

  const deleteProduct = async (storeId, productId) => {
    confirmAlert({
      title: "Confirmar eliminar",
      message: "¿Estás seguro que quieres eliminar este producto?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            db
              .collection("stores")
              .doc(storeId)
              .collection("products")
              .doc(productId)
              .delete()
              .then(function () {
                console.log("Document successfully deleted!");
              })
              .catch(function (error) {
                console.error("Error removing document: ", error);
              }),
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };
  const editProduct = async (storeId, productId, product) => {
    // history.push(`/store/${storeId}/product/${productId}/edit`);
    history.push({
      pathname: `/store/${storeId}/product/${productId}/edit`,
      item: product,
    });
  };

  useEffect(() => {
    if (valueProducts) {
      setItemList(valueProducts.docs);
    }
  }, [valueProducts]);

  const saveValue = useCallback(() => {
    itemList.map((doc, i) => {
      db.collection("stores")
        .doc(storeId)
        .collection("products")
        .doc(doc.id)
        .update({
          order: parseInt(i),
        })
        .then(function () {
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    });
    setIsChange(false);
  }, [setIsChange, itemList, storeId]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    setItemList(arrayMove(itemList, oldIndex, newIndex));
    setIsChange(true);
  };
  return {
    isChange,
    saveValue,
    storeId,
    valueProducts,
    loadingProducts,
    errorProduts,
    deleteProduct,
    editProduct,
    isModalOpen,
    toggleModal,
    itemList,
    onSortEnd,
  };
};

export default useConnect;
