import { useHistory } from "react-router-dom";
import { useState } from "react";

const useConnect = () => {
  const history = useHistory();

  const handleCreateStore = () => {
    history.push("/store/create");
  };

  const goToStore = (storeId) => {
    history.push(`/store/${storeId}/view`);
  };

  const [open, setOpen] = useState(false);

  const handleOpenCreateStoreModal = () => setOpen(true);
  const handleCloseCreateStoreModal = () => {
    setOpen(false);
  };

  return {
    open,
    handleOpenCreateStoreModal,
    handleCloseCreateStoreModal,
    handleCreateStore,
    goToStore,
  };
};

export default useConnect;
