import React from "react";
import { Formik, Field } from "formik";
import useConnect from "./connect";
import { initialValues, validationSchema } from "./constants";
import {
  Container,
  Title,
  Subtitle,
  Form,
  Input,
  Button,
  Link,
  BackIcon,
  Text,
} from "./styles";

const ResetPassword = () => {
  const { handleSubmit, isSuccessful } = useConnect();

  return (
    <Container>
      <Link>
        <BackIcon />
        Volver a la página de inicio
      </Link>
      <Title>Restablecer contraseña</Title>
      {isSuccessful ? (
        <Text>
          Gracias! Por favor verifica tu correo y sigue las instrucciones para
          restablecer tu contraseña.
        </Text>
      ) : (
        <>
          <Subtitle>
            Introduce tu email y te enviaremos instrucciones sobre cómo
            restablecer su contraseña.
          </Subtitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <Field as={Input} label="Email" name="email" type="email" />
                <Button disabled={isSubmitting} type="submit">
                  Restablecer contraseña
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
