import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as ROUTES from "constants/routes";
import Home from "./Home";
import Success from "containers/success";
// import CreateStore from "containers/Main/store/Create";
import ModalEditStore from "containers/Main/Store/Edit";
// import CreateStore from "containers/Stores/CreateStore";
import Store from "containers/Main/Store";
// import QrStore from "containers/stores/QrStore";
import CreateProduct from "containers/Main/Products/Create";
import EditProduct from "containers/Main/Products/Edit";
// import ProductOrderList from "containers/Stores/products/Order";
import CreateCategory from "containers/Main/Categories/Create";
import EditCategory from "containers/Main/Categories/Edit";
// import OrderCategoriesList from "containers/stores/categories/OrderCategoriesList";
// import ViewStore from "containers/Main/store/View";

const Main = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME} component={Home} />
      <Route path={ROUTES.SUCCESS} component={Success} />
      <Route path={ROUTES.CREATE_PRODUCT} component={CreateProduct} />
      <Route path={ROUTES.EDIT_PRODUCT} component={EditProduct} />
      <Route path={ROUTES.CREATE_CATEGORY} component={CreateCategory} />
      <Route path={ROUTES.EDIT_CATEGORY} component={EditCategory} />
      {/* <Route exact path={ROUTES.CREATE_STORE} component={CreateStore} /> */}
      {/* <Route exact path={ROUTES.QR_STORE} component={QrStore} /> */}
      {/* <Route path={ROUTES.ORDER_PRODUCTS} component={ProductOrderList} /> */}
      {/* <Route path={ROUTES.ORDER_CATEGORIES} component={OrderCategoriesList} /> */}
      {/* <Route path={ROUTES.VIEW_STORE} component={ViewStore} /> */}
      <Route path={ROUTES.EDIT_STORE} component={ModalEditStore} />
      <Route path={ROUTES.STORE} component={Store} />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  );
};

export default Main;
