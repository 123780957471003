import styled from "styled-components";
import { FiCopy } from "react-icons/fi";
import DefaultButton from "components/Button";
import { Overline } from "components/Typography";

export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.gray2};
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  padding: 0.875rem 0.75rem;
  width: 100%;
  opacity: 0.5;
`;

export const CopyIon = styled(FiCopy)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Button = styled(DefaultButton)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export const Caption = styled(Overline)`
  margin-left: 0.625rem;
`;
