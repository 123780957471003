import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as ROUTES from "constants/routes";
import SignIn from "containers/Guest/Login";
import SignUp from "containers/Guest/Register";
import ForgotPassword from "containers/Guest/ResetPassword";

import {
  Container,
  Cover,
  CoverContent,
  Logo,
  Link,
  Text,
  Title,
  View,
  ViewContent,
} from "./styles";

const Guest = () => (
  <Container>
    <Cover>
      <CoverContent>
        <Link to="/">
          <Logo width="40px" />
        </Link>
        <Title>La solución QR para tu Restaurante</Title>
        <Text>
          Crea tu carta digital para tu restaurante en minutos y personaliza tu
          código QR.
        </Text>
      </CoverContent>
      {/* <Illustration alt="Guest illustration" src={GuestIllustration} /> */}
    </Cover>
    <View>
      <ViewContent>
        <Switch>
          <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
          <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
          <Route
            exact
            path={ROUTES.PASSWORD_FORGET}
            component={ForgotPassword}
          />
          <Redirect to={ROUTES.SIGN_IN} />
        </Switch>
      </ViewContent>
    </View>
  </Container>
);

export default Guest;
