import styled from "styled-components";
import DefaultButton from "components/Button";
import DefaultAddButton from "components/AddButton";
import { H1 } from "components/Typography";

export const Button = styled(DefaultButton)`
  margin-top: 1.875rem;
`;

export const Container = styled.div``;

export const Row = styled.div``;

export const Title = styled(H1)``;

export const AddButton = styled(DefaultAddButton)`
  margin-bottom: 40px;
`;
