import React from "react";
import {
  Row,
  Switch,
  Button,
  Text,
  Title,
  WrapperQR,
  QRCode,
  WrapperColors,
  ColorPicker,
} from "./styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useConnect from "./connect";
import { getThumbnailURL } from "helpers";
import CopyButton from "components/CopyButton";

export default function Qr() {
  const {
    storeId,
    downloadQR,
    state,
    handleChange,
    color,
    setColor,
    background,
    setBackground,
    qrRef,
    valueStore,
  } = useConnect();

  return (
    <>
      <Row>
        <div>
          <Title>Personaliza tu código QR.</Title>
          <Text>Usa este código qr para acceder a tu carta.</Text>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={state}
                  onChange={handleChange}
                  name="checked"
                />
              }
              label="Mostrar logo"
            />
          </div>
          <WrapperColors>
            <ColorPicker
              defaultValue="Color"
              value={color}
              onChange={(newColor) => setColor(newColor)}
            />
            <ColorPicker
              defaultValue={"Fondo"}
              value={background}
              onChange={(newColor) => setBackground(newColor)}
            />
          </WrapperColors>

          <Button onClick={downloadQR}>Descargar código QR</Button>
        </div>
        <WrapperQR>
          <QRCode
            crossOrigin="anonymous"
            ref={qrRef}
            enableCORS
            value={`https://noweat.io/carta/${storeId}`}
            qrStyle="dots"
            size={1200}
            logoImage={
              state && valueStore && getThumbnailURL(valueStore.data().logo)
            }
            fgColor={color}
            bgColor={background}
          />
        </WrapperQR>
        <CopyButton text={`https://noweat.io/carta/${storeId}`} />
      </Row>
    </>
  );
}
