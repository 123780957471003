import React from "react";
import { Form, Formik, Field } from "formik";
import useConnect from "./connect";
import { initialValues, validationSchema } from "./constants";
import {
  Container,
  Title,
  Subtitle,
  Input,
  FormButton,
  OtherAction,
  Caption,
  SecondaryButton,
  Policy,
  Link,
} from "./styles";

const Login = () => {
  const { handleRegisterRedirect, handleSubmit } = useConnect();

  return (
    <Container>
      <Title>Crear cuenta en nowEat</Title>
      <Subtitle>Pronto estarás creando tu carta con código qr</Subtitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Field as={Input} label="Email" name="email" type="email" />
            <Field
              as={Input}
              label="Password"
              name="password"
              type="password"
            />
            <FormButton disabled={isSubmitting} type="submit">
              Crear cuenta
            </FormButton>
          </Form>
        )}
      </Formik>
      <Policy>
        Al registrarse, confirma que acepta nuestras{" "}
        <Link
          href="https://noweat.io/legal/terminos-y-condiciones"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terminos y condiciones
        </Link>{" "}
        y{" "}
        <Link
          href="https://noweat.io/legal/aviso-legal"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidad.
        </Link>
      </Policy>

      <OtherAction>
        <Caption>¿Ya tienes cuenta?</Caption>
        <SecondaryButton onClick={handleRegisterRedirect}>
          Iniciar Sesión
        </SecondaryButton>
      </OtherAction>
    </Container>
  );
};

export default Login;
